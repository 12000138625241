import { Button, Card, Container } from "react-bootstrap";
import "../../Assets/Style/unsubscribe.css";
import axios from "axios";
import { useLocation } from "react-router";
import { useState } from "react";

const Unsubscribe = () => {
  const [response, setResponse] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const code = searchParams.get("code");

  const onUnsubscribe = async (email, code) => {
    if (email && code) {
      const values = {
        email: email,
        code: code,
      };
      try {
        const response = await axios.post(
          "https://backend.intellattract.ai/email/unsubscribe/",
          values
        );
        if (response.status === 200) {
          setResponse(response.data.msg);
        }
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="unsubscribe">
      <Container>
        <Card>
          <Card.Body>
            <Card.Title className="title">
              <h1>Wonderbots AI Email Subscription</h1>
              {response == "Unsubscribed" && (
                <h2>
                  You have successfully unsubscribed from Wonderbots AI emails
                </h2>
              )}
            </Card.Title>
            {response !== "Unsubscribed" && (
              <Button
                onClick={() => onUnsubscribe(email, code)}
                className="page-btn"
              >
                Unsubscribe
              </Button>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
export default Unsubscribe;
