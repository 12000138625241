import "./App.css";
import Unsubscribe from "./Pages/Unsubscribe/unsubscribe";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/notifications/unsubscribe-email"
            element={<Unsubscribe />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
